import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Card,
  CardContent,
  CardMedia,
  Box,
  TextField,
  Typography,
  Button,
  CssBaseline,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LogoutIcon from '@mui/icons-material/Logout';
import VideoUploader from './VideoUploader'; 
import PreviewPlayer from './Previewplayer';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
    text: {
      primary: '#ffffff',
      secondary: '#b0b0b0',
    },
  },
  typography: {
    fontFamily: 'Oswald, sans-serif',
  },
});

function ProtectedPage() {
  // Authentication States
  const [password, setPassword] = useState(localStorage.getItem('password') || '');
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('password'));
  const [error, setError] = useState('');

  // Video Data States
  const [videoData, setVideoData] = useState({});
  const [editableField, setEditableField] = useState({});
  const [expandedCards, setExpandedCards] = useState({});
  const [playingVideos, setPlayingVideos] = useState({});

  // Dialog States
  const [deleteDialog, setDeleteDialog] = useState({ open: false, key: '', fieldKey: '' });
  const [addFieldDialog, setAddFieldDialog] = useState({ open: false, key: '', fieldKey: '', value: '' });
  const [addVideoDialog, setAddVideoDialog] = useState({
    open: false,
    key: '',
    fields: {
      title: '',
      description: '',
      linktext: '',
      link: '',
      frontend: false,
      public: false,
      products: '',
      category: '',
      chatbot: '',
    },
  });
  const [deleteVideoDialog, setDeleteVideoDialog] = useState({ open: false, key: '' });
  const [uploadThumbnailDialog, setUploadThumbnailDialog] = useState({ open: false, key: '', file: null });
  const [subtitleDialog, setSubtitleDialog] = useState({
    open: false,
    key: '',
    index: null,
    subtitle: { label: '', srclang: '', src: '', default: false },
  });
  const [assetDialog, setAssetDialog] = useState({ open: false, key: '', asset: { hls: '', mp4: '' } });
  const [autoSubtitleDialog, setAutoSubtitleDialog] = useState({
    open: false,
    key: '',
    vttContent: '',
    loading: false,
  });

  // Notification States
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    if (isAuthenticated) {
      fetchVideoData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // Authentication Handler
  const handleAuthentication = () => {
    if (password) {
      localStorage.setItem('password', password);
      setIsAuthenticated(true);
      setError('');
      setNotification({ open: true, message: 'Authenticated successfully!', severity: 'success' });
    } else {
      setError('Password cannot be empty.');
      setNotification({ open: true, message: 'Password cannot be empty.', severity: 'error' });
    }
  };

  
  const handleVideoUploadSuccess = (key, assets) => {
    const updatedVideoData = {
      ...videoData[key],
      assets: {
        hls: assets.hls,
        mp4: assets.mp4,
      },
      thumbnailUrl: assets.thumbnail,
    };
  
    // Save updated video data via API
    axios
      .post(
        'https://learn.philspeiser.com/api/addupdate',
        {
          ...updatedVideoData,
          password: localStorage.getItem('password'),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      .then(() => {
        setVideoData((prevData) => ({
          ...prevData,
          [key]: updatedVideoData,
        }));
        setNotification({
          open: true,
          message: 'Video uploaded and assets updated successfully',
          severity: 'success',
        });
      })
      .catch((error) => {
        console.error('Error updating video data:', error);
        setError('Failed to update video data.');
        setNotification({
          open: true,
          message: 'Failed to update video data.',
          severity: 'error',
        });
      });
  };

  // Logout Handler
  const handleLogout = () => {
    localStorage.removeItem('password');
    setIsAuthenticated(false);
    setPassword('');
    setVideoData({});
    setNotification({ open: true, message: 'Logged out successfully!', severity: 'info' });
  };

  // Fetch Video Data
  const fetchVideoData = async () => {
    try {
      const response = await axios.get('https://learn.philspeiser.com/api/getall');
      console.log(response.data);
      setVideoData(response.data || {});
    } catch (err) {
      setError('Failed to fetch video data.');
      setNotification({ open: true, message: 'Failed to fetch video data.', severity: 'error' });
    }
  };

  // Copy Link to Clipboard
  const copyToClipboard = (key) => {
    const link = `https://learn.philspeiser.com/watch/${key}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setNotification({ open: true, message: 'Link copied to clipboard!', severity: 'success' });
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
        setNotification({ open: true, message: 'Failed to copy link.', severity: 'error' });
      });
  };

  // Handle Field Edit
  const handleEditClick = (key, fieldKey, fieldValue) => {
    setEditableField({ key, fieldKey, value: fieldValue });
  };

  // Handle Save Edited Field
  const handleSaveClick = async (e) => {
    e.preventDefault();
    const { key, fieldKey, value } = editableField;

    // Clone the video data to modify
    const updatedVideoData = {
      ...videoData[key],
      [fieldKey]: value,
    };

    try {
      const response = await axios.post(
        'https://learn.philspeiser.com/api/addupdate',
        {
          ...updatedVideoData,
          password: localStorage.getItem('password'),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setVideoData((prevData) => ({
          ...prevData,
          [key]: updatedVideoData,
        }));
        setEditableField({});
        setNotification({ open: true, message: 'Video data updated successfully', severity: 'success' });
      }
    } catch (error) {
      console.error('Error updating video data:', error);
      setError('Failed to update video data.');
      setNotification({ open: true, message: 'Failed to update video data.', severity: 'error' });
    }
  };

  // Handle Adding a New Field
  const handleAddField = async () => {
    const { key, fieldKey, value } = addFieldDialog;

    if (!key || !fieldKey) {
      setError('Please provide both key and field name.');
      setNotification({
        open: true,
        message: 'Please provide both key and field name.',
        severity: 'warning',
      });
      return;
    }

    const updatedVideoData = {
      ...videoData[key],
      [fieldKey]: value,
    };

    try {
      const response = await axios.post(
        'https://learn.philspeiser.com/api/addupdate',
        {
          ...updatedVideoData,
          password: localStorage.getItem('password'),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setVideoData((prevData) => ({
          ...prevData,
          [key]: updatedVideoData,
        }));
        setAddFieldDialog({ open: false, key: '', fieldKey: '', value: '' });
        setNotification({ open: true, message: 'Field added successfully', severity: 'success' });
      }
    } catch (error) {
      console.error('Error adding field:', error);
      setError('Failed to add field.');
      setNotification({ open: true, message: 'Failed to add field.', severity: 'error' });
    }
  };

  // Handle Deleting a Field
  const handleDeleteField = async () => {
    const { key, fieldKey } = deleteDialog;
    const updatedVideoData = { ...videoData[key] };
    delete updatedVideoData[fieldKey];

    try {
      const response = await axios.post(
        'https://learn.philspeiser.com/api/addupdate',
        {
          ...updatedVideoData,
          password: localStorage.getItem('password'),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setVideoData((prevData) => ({
          ...prevData,
          [key]: updatedVideoData,
        }));
        setDeleteDialog({ open: false, key: '', fieldKey: '' });
        setNotification({ open: true, message: 'Field deleted successfully', severity: 'success' });
      }
    } catch (error) {
      console.error('Error deleting field:', error);
      setError('Failed to delete field.');
      setNotification({ open: true, message: 'Failed to delete field.', severity: 'error' });
    }
  };

  // Handle Adding a New Video
  const handleAddVideo = async () => {
    const { key, fields } = addVideoDialog;

    if (!key) {
      setError('Please provide a video key.');
      setNotification({ open: true, message: 'Please provide a video key.', severity: 'warning' });
      return;
    }

    const newVideoData = {
      identifier: key,
      ...fields,
      assets: {
        hls: '',
        mp4: '',
      },
      subtitles: [],
      chapters: [],
      password: localStorage.getItem('password'),
    };

    try {
      const response = await axios.post('https://learn.philspeiser.com/api/addupdate', newVideoData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setVideoData((prevData) => ({
          ...prevData,
          [key]: newVideoData,
        }));
        setAddVideoDialog({
          open: false,
          key: '',
          fields: {
            title: '',
            description: '',
            linktext: '',
            link: '',
            frontend: false,
            public: false,
            products: '',
            category: '',
            chatbot: '',
          },
        });
        setNotification({ open: true, message: 'Video added successfully', severity: 'success' });
      }
    } catch (error) {
      console.error('Error adding video:', error);
      setError('Failed to add video.');
      setNotification({ open: true, message: 'Failed to add video.', severity: 'error' });
    }
  };

  // Handle Deleting a Video
  const handleDeleteVideo = async () => {
    const { key } = deleteVideoDialog;

    try {
      const response = await axios.delete('https://learn.philspeiser.com/api/deletevideo', {
        params: {
          identifier: key,
          password: localStorage.getItem('password'),
        },
      });

      if (response.status === 200) {
        const updatedVideoData = { ...videoData };
        delete updatedVideoData[key];
        setVideoData(updatedVideoData);
        setDeleteVideoDialog({ open: false, key: '' });
        setNotification({ open: true, message: 'Video deleted successfully', severity: 'success' });
      }
    } catch (error) {
      console.error('Error deleting video:', error);
      setError('Failed to delete video.');
      setNotification({ open: true, message: 'Failed to delete video.', severity: 'error' });
    }
  };

  // Handle Uploading Thumbnail
  const handleUploadThumbnail = (key) => {
    setUploadThumbnailDialog({ open: true, key, file: null });
  };

  const handleUploadThumbnailSubmit = async () => {
    const { key, file } = uploadThumbnailDialog;
    if (!file) {
      setError('Please select a file.');
      setNotification({ open: true, message: 'Please select a file.', severity: 'warning' });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('identifier', key);
    formData.append('password', localStorage.getItem('password'));

    try {
      const response = await axios.put('https://serve.philspeiser.com/api/uploadthumbnail', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const { url } = response.data;
      const updatedVideoData = {
        ...videoData[key],
        thumbnailUrl: url,
      };

      const updateResponse = await axios.post(
        'https://learn.philspeiser.com/api/addupdate',
        {
          ...updatedVideoData,
          password: localStorage.getItem('password'),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (updateResponse.status === 200) {
        setVideoData((prevData) => ({
          ...prevData,
          [key]: updatedVideoData,
        }));
        setUploadThumbnailDialog({ open: false, key: '', file: null });
        setNotification({ open: true, message: 'Thumbnail uploaded successfully', severity: 'success' });
      }
    } catch (error) {
      console.error('Error uploading thumbnail:', error);
      setError('Failed to upload thumbnail.');
      setNotification({ open: true, message: 'Failed to upload thumbnail.', severity: 'error' });
    }
  };

  const handleToggleVideo = (key) => {
    setPlayingVideos(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };
  

  // Handle Subtitle Operations
  const handleAddSubtitle = (key) => {
    setSubtitleDialog({
      open: true,
      key,
      index: null,
      subtitle: { label: '', srclang: '', src: '', default: false },
    });
  };

  const handleEditSubtitle = (key, index, subtitle) => {
    setSubtitleDialog({ open: true, key, index, subtitle });
  };

  const handleDeleteSubtitle = async (key, index) => {
    const updatedSubtitles = videoData[key]?.subtitles ? [...videoData[key].subtitles] : [];
    if (updatedSubtitles.length > index) {
      updatedSubtitles.splice(index, 1);
    } else {
      // If index is out of bounds, do nothing or handle accordingly
      setNotification({ open: true, message: 'Subtitle not found.', severity: 'error' });
      return;
    }

    const updatedVideoData = {
      ...videoData[key],
      subtitles: updatedSubtitles,
    };

    try {
      const response = await axios.post(
        'https://learn.philspeiser.com/api/addupdate',
        {
          ...updatedVideoData,
          password: localStorage.getItem('password'),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setVideoData((prevData) => ({
          ...prevData,
          [key]: updatedVideoData,
        }));
        setNotification({ open: true, message: 'Subtitle deleted successfully', severity: 'success' });
      }
    } catch (error) {
      console.error('Error deleting subtitle:', error);
      setError('Failed to delete subtitle.');
      setNotification({ open: true, message: 'Failed to delete subtitle.', severity: 'error' });
    }
  };

  const handleSubtitleSubmit = async () => {
    const { key, index, subtitle } = subtitleDialog;

    if (!subtitle.label || !subtitle.srclang || !subtitle.src) {
      setError('Please fill in all subtitle fields.');
      setNotification({ open: true, message: 'Please fill in all subtitle fields.', severity: 'warning' });
      return;
    }

    let updatedSubtitles = videoData[key]?.subtitles ? [...videoData[key].subtitles] : [];

    if (index === null) {
      // Adding a new subtitle
      updatedSubtitles.push(subtitle);
    } else if (updatedSubtitles.length > index) {
      // Editing an existing subtitle
      updatedSubtitles[index] = subtitle;
    } else {
      setNotification({ open: true, message: 'Subtitle not found.', severity: 'error' });
      return;
    }

    const updatedVideoData = {
      ...videoData[key],
      subtitles: updatedSubtitles,
    };

    try {
      const response = await axios.post(
        'https://learn.philspeiser.com/api/addupdate',
        {
          ...updatedVideoData,
          password: localStorage.getItem('password'),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setVideoData((prevData) => ({
          ...prevData,
          [key]: updatedVideoData,
        }));
        setSubtitleDialog({
          open: false,
          key: '',
          index: null,
          subtitle: { label: '', srclang: '', src: '', default: false },
        });
        setNotification({
          open: true,
          message: index === null ? 'Subtitle added successfully' : 'Subtitle updated successfully',
          severity: 'success',
        });
      }
    } catch (error) {
      console.error('Error updating subtitles:', error);
      setError('Failed to update subtitles.');
      setNotification({ open: true, message: 'Failed to update subtitles.', severity: 'error' });
    }
  };

  // Handle Asset Operations
  const handleEditAssets = (key) => {
    setAssetDialog({
      open: true,
      key,
      asset: { hls: videoData[key]?.assets?.hls || '', mp4: videoData[key]?.assets?.mp4 || '' },
    });
  };

  const handleAssetSubmit = async () => {
    const { key, asset } = assetDialog;

    if (!asset.hls || !asset.mp4) {
      setError('Please fill in all asset fields.');
      setNotification({ open: true, message: 'Please fill in all asset fields.', severity: 'warning' });
      return;
    }

    const updatedVideoData = {
      ...videoData[key],
      assets: asset,
    };

    try {
      const response = await axios.post(
        'https://learn.philspeiser.com/api/addupdate',
        {
          ...updatedVideoData,
          password: localStorage.getItem('password'),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setVideoData((prevData) => ({
          ...prevData,
          [key]: updatedVideoData,
        }));
        setAssetDialog({ open: false, key: '', asset: { hls: '', mp4: '' } });
        setNotification({ open: true, message: 'Assets updated successfully', severity: 'success' });
      }
    } catch (error) {
      console.error('Error updating assets:', error);
      setError('Failed to update assets.');
      setNotification({ open: true, message: 'Failed to update assets.', severity: 'error' });
    }
  };

  // Handle Accordion Expand
  const handleExpandClick = (key) => {
    setExpandedCards((prevExpandedCards) => ({
      ...prevExpandedCards,
      [key]: !prevExpandedCards[key],
    }));
  };

  // Close Notification
  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  // Handle Auto-Generate Subtitles
  const handleAutoGenerateSubtitles = (key) => {
    setAutoSubtitleDialog({ open: true, key, vttContent: '', loading: false });
  };

  const handleAutoSubtitleSubmit = async () => {
    const { key, vttContent } = autoSubtitleDialog;

    if (!vttContent) {
      setError('Please provide VTT content.');
      setNotification({
        open: true,
        message: 'Please provide VTT content.',
        severity: 'warning',
      });
      return;
    }

    setAutoSubtitleDialog((prev) => ({ ...prev, loading: true }));

    try {
      // Send the request to your endpoint
      await axios.post('https://eopx31rej3ojhld.m.pipedream.net', {
        filename: key,
        subtitledata: vttContent,
      });

      // Notify the user
      setNotification({
        open: true,
        message: 'Subtitles are being processed and will be added shortly.',
        severity: 'info',
      });

      // Optionally, refresh the video data after some delay
      // setTimeout(fetchVideoData, 60000); // Refresh after 1 minute
    } catch (error) {
      console.error('Error initiating subtitle processing:', error);
      setError('Failed to initiate subtitle processing.');
      setNotification({
        open: true,
        message: 'Failed to initiate subtitle processing.',
        severity: 'error',
      });
    } finally {
      setAutoSubtitleDialog({ open: false, key: '', vttContent: '', loading: false });
    }
  };

  // Render Authentication Form
  if (!isAuthenticated) {
    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh" p={2}>
          <Typography variant="h4" gutterBottom>
            Authentication Required
          </Typography>
          <TextField
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            variant="outlined"
            margin="dense"
            color="primary"
            fullWidth
          />
          <Button variant="contained" color="primary" onClick={handleAuthentication} sx={{ mt: 2 }}>
            Authenticate
          </Button>
          {error && (
            <Typography color="error" mt={2}>
              {error}
            </Typography>
          )}
        </Box>
      </ThemeProvider>
    );
  }

  // Render Main Content
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box p={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h4" gutterBottom>
            THE_VIDEOS
          </Typography>
          <Button variant="outlined" color="secondary" startIcon={<LogoutIcon />} onClick={handleLogout}>
            Logout
          </Button>
        </Box>
        <Box display="flex" alignItems="center" mb={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() =>
              setAddVideoDialog({
                open: true,
                key: '',
                fields: {
                  title: '',
                  description: '',
                  linktext: '',
                  link: '',
                  frontend: false,
                  public: false,
                  products: '',
                  category: '',
                  chatbot: '',
                },
              })
            }
          >
            Add Video
          </Button>
          <Button variant="outlined" color="primary" onClick={fetchVideoData} sx={{ ml: 2 }}>
            Refresh Data
          </Button>
        </Box>
        {Object.keys(videoData).length > 0 ? (
          <Box>
            {Object.keys(videoData)
              .filter((key) => videoData[key] !== null)
              .map((key) => {
                const isExpanded = expandedCards[key] || false;
                const video = videoData[key];
                return (
                  <Box mb={2} key={key}>
                    <Card>
                      <CardContent>
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="h6"
                            component="div"
                            gutterBottom
                            onClick={() => handleExpandClick(key)}
                            sx={{ cursor: 'pointer', flex: 1 }}
                          >
                            {video.title || 'Untitled Video'}
                          </Typography>
                          <Tooltip title="Copy link">
                            <IconButton onClick={() => copyToClipboard(key)}>
                              <ContentCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </CardContent>
                      {isExpanded && (
                        <>
                           <Box sx={{ position: 'relative' }}>
                    {!playingVideos[key] && video.thumbnailUrl && (
                      <CardMedia
                        component="img"
                        height="200"
                        image={video.thumbnailUrl}
                        alt="Thumbnail"
                        sx={{ objectFit: 'contain' }}
                      />
                    )}
                    {isExpanded && video.assets?.hls && (
  <Box sx={{ p: 2, bgcolor: 'background.paper' }}>
    <Button
      variant="contained"
      color="primary"
      onClick={() => handleToggleVideo(key)}
      sx={{ mb: 2 }}
    >
      {playingVideos[key] ? 'Hide Preview' : 'Show Preview'}
    </Button>
    {playingVideos[key] && (
      <Box 
        sx={{ 
          width: '100%',
          maxWidth: '800px',
          margin: '0 auto',
        }}
      >
       <PreviewPlayer
      key={`preview-${key}`}
      hlsUrl={video.assets.hls}
      mp4Url={video.assets.mp4}
      subtitles={video.subtitles}
      videoData={video}
      identifier={key}
      onUpdate={(updatedData) => {
        setVideoData(prev => ({
          ...prev,
          [key]: updatedData
        }));
      }}
    />
      </Box>
    )}
  </Box>
)}
                  
                  </Box>
                  <CardContent>
                            {/* Editable Fields */}
                            {Object.entries(video).map(([fieldKey, fieldValue]) => {
                              if (['assets', 'subtitles', 'chapters', 'identifier'].includes(fieldKey)) return null;
                              return (
                                <Box key={fieldKey} mb={1} display="flex" alignItems="center">
                                  <TextField
                                    fullWidth
                                    label={fieldKey.charAt(0).toUpperCase() + fieldKey.slice(1)}
                                    value={
                                      editableField.key === key && editableField.fieldKey === fieldKey
                                        ? editableField.value
                                        : fieldValue
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    InputProps={{
                                      readOnly: !(editableField.key === key && editableField.fieldKey === fieldKey),
                                    }}
                                    onChange={(e) =>
                                      setEditableField((prev) => ({ ...prev, value: e.target.value }))
                                    }
                                  />
                                  {editableField.key === key && editableField.fieldKey === fieldKey ? (
                                    <IconButton color="primary" onClick={handleSaveClick} sx={{ ml: 1 }}>
                                      <SaveIcon />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      color="secondary"
                                      onClick={() => handleEditClick(key, fieldKey, fieldValue)}
                                      sx={{ ml: 1 }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  )}
                                  <IconButton
                                    color="error"
                                    onClick={() => setDeleteDialog({ open: true, key, fieldKey })}
                                    sx={{ ml: 1 }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              );
                            })}

                            {/* Assets Section */}
                            <Accordion expanded={isExpanded}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Assets</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box mb={1} display="flex" alignItems="center">
                                  <TextField
                                    fullWidth
                                    label="HLS URL"
                                    value={video.assets?.hls || ''}
                                    variant="outlined"
                                    margin="dense"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                  <IconButton
                                    color="secondary"
                                    onClick={() => handleEditAssets(key)}
                                    sx={{ ml: 1 }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Box>
                                <Box mb={1} display="flex" alignItems="center">
                                  <TextField
                                    fullWidth
                                    label="MP4 URL"
                                    value={video.assets?.mp4 || ''}
                                    variant="outlined"
                                    margin="dense"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                  <IconButton
                                    color="secondary"
                                    onClick={() => handleEditAssets(key)}
                                    sx={{ ml: 1 }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Box>
                              </AccordionDetails>
                            </Accordion>

                            <Box mt={2}>
                            <VideoUploader
                              identifier={key}
                              onUploadSuccess={(assets) => handleVideoUploadSuccess(key, assets)}
                            />
                          </Box>

                            {/* Subtitles Section */}
                            <Accordion expanded={isExpanded}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Subtitles</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {video.subtitles && video.subtitles.length > 0 ? (
                                  video.subtitles.map((subtitle, index) => (
                                    <Box key={index} mb={1} display="flex" alignItems="center">
                                      <Typography variant="body2" sx={{ flex: 1 }}>
                                        {`${subtitle.label} (${subtitle.srclang})`}
                                      </Typography>
                                      <IconButton
                                        color="secondary"
                                        onClick={() => handleEditSubtitle(key, index, subtitle)}
                                        sx={{ ml: 1 }}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                      <IconButton
                                        color="error"
                                        onClick={() => handleDeleteSubtitle(key, index)}
                                        sx={{ ml: 1 }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Box>
                                  ))
                                ) : (
                                  <Typography variant="body2">No subtitles available.</Typography>
                                )}
                                <Button
                                  variant="outlined"
                                  startIcon={<AddIcon />}
                                  onClick={() => handleAddSubtitle(key)}
                                  sx={{ mt: 1 }}
                                >
                                  Add Subtitle
                                </Button>
                                <Button
                                  variant="outlined"
                                  startIcon={<AddIcon />}
                                  onClick={() => handleAutoGenerateSubtitles(key)}
                                  sx={{ mt: 1, ml: 1 }}
                                >
                                  Auto-Generate Subtitles
                                </Button>
                              </AccordionDetails>
                            </Accordion>

                            {/* Chapters Section */}
                            <Accordion expanded={isExpanded}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Chapters</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {video.chapters && video.chapters.length > 0 ? (
                                  video.chapters.map((chapter, index) => (
                                    <Box key={index} mb={1} display="flex" alignItems="center">
                                      <Typography variant="body2" sx={{ flex: 1 }}>
                                        {`Time: ${chapter.time}s - ${chapter.title}`}
                                      </Typography>
                                      {/* Implement edit and delete for chapters if needed */}
                                    </Box>
                                  ))
                                ) : (
                                  <Typography variant="body2">No chapters available.</Typography>
                                )}
                                {/* Add functionality to add/edit/delete chapters if required */}
                              </AccordionDetails>
                            </Accordion>

                            {/* Action Buttons */}
                            <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
                              <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={() =>
                                  setAddFieldDialog({ open: true, key, fieldKey: '', value: '' })
                                }
                              >
                                Add Field
                              </Button>
                              <Button
                                variant="contained"
                                color="error"
                                startIcon={<DeleteIcon />}
                                onClick={() => setDeleteVideoDialog({ open: true, key })}
                              >
                                Delete Video
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleUploadThumbnail(key)}
                              >
                                Add/Replace Thumbnail
                              </Button>
                            </Box>
                          </CardContent>
                        </>
                      )}
                    </Card>
                  </Box>
                );
              })}
          </Box>
        ) : (
          <Typography>No videos available. Please add a video.</Typography>
        )}
      </Box>

      {/* Delete Field Dialog */}
      <Dialog
        open={deleteDialog.open}
        onClose={() => setDeleteDialog({ open: false, key: '', fieldKey: '' })}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the field "{deleteDialog.fieldKey}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog({ open: false, key: '', fieldKey: '' })} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteField} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Field Dialog */}
      <Dialog
        open={addFieldDialog.open}
        onClose={() => setAddFieldDialog({ open: false, key: '', fieldKey: '', value: '' })}
      >
        <DialogTitle>Add New Field</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Field Name"
                value={addFieldDialog.fieldKey}
                onChange={(e) => setAddFieldDialog((prev) => ({ ...prev, fieldKey: e.target.value }))}
                variant="outlined"
                margin="dense"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Field Value"
                value={addFieldDialog.value}
                onChange={(e) => setAddFieldDialog((prev) => ({ ...prev, value: e.target.value }))}
                variant="outlined"
                margin="dense"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setAddFieldDialog({ open: false, key: '', fieldKey: '', value: '' })}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleAddField} color="primary">
            Add Field
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Video Dialog */}
      <Dialog
        open={addVideoDialog.open}
        onClose={() =>
          setAddVideoDialog({
            open: false,
            key: '',
            fields: {
              title: '',
              description: '',
              linktext: '',
              link: '',
              frontend: false,
              public: false,
              products: '',
              category: '',
              chatbot: '',
            },
          })
        }
      >
        <DialogTitle>Add New Video</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Video Key */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Video Key"
                value={addVideoDialog.key}
                onChange={(e) => setAddVideoDialog((prev) => ({ ...prev, key: e.target.value }))}
                variant="outlined"
                margin="dense"
              />
            </Grid>
            {/* Title */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title"
                value={addVideoDialog.fields.title}
                onChange={(e) =>
                  setAddVideoDialog((prev) => ({ ...prev, fields: { ...prev.fields, title: e.target.value } }))
                }
                variant="outlined"
                margin="dense"
              />
            </Grid>
            {/* Description */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                value={addVideoDialog.fields.description}
                onChange={(e) =>
                  setAddVideoDialog((prev) => ({
                    ...prev,
                    fields: { ...prev.fields, description: e.target.value },
                  }))
                }
                variant="outlined"
                margin="dense"
                multiline
                rows={3}
              />
            </Grid>
            {/* Link Text */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Link Text"
                value={addVideoDialog.fields.linktext}
                onChange={(e) =>
                  setAddVideoDialog((prev) => ({
                    ...prev,
                    fields: { ...prev.fields, linktext: e.target.value },
                  }))
                }
                variant="outlined"
                margin="dense"
              />
            </Grid>
            {/* Link */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Link"
                value={addVideoDialog.fields.link}
                onChange={(e) =>
                  setAddVideoDialog((prev) => ({ ...prev, fields: { ...prev.fields, link: e.target.value } }))
                }
                variant="outlined"
                margin="dense"
              />
            </Grid>
            {/* Frontend */}
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={addVideoDialog.fields.frontend}
                    onChange={(e) =>
                      setAddVideoDialog((prev) => ({
                        ...prev,
                        fields: { ...prev.fields, frontend: e.target.checked },
                      }))
                    }
                    color="primary"
                  />
                }
                label="Frontend"
              />
            </Grid>
            {/* Public */}
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={addVideoDialog.fields.public}
                    onChange={(e) =>
                      setAddVideoDialog((prev) => ({
                        ...prev,
                        fields: { ...prev.fields, public: e.target.checked },
                      }))
                    }
                    color="primary"
                  />
                }
                label="Public"
              />
            </Grid>
            {/* Products */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Products"
                value={addVideoDialog.fields.products}
                onChange={(e) =>
                  setAddVideoDialog((prev) => ({
                    ...prev,
                    fields: { ...prev.fields, products: e.target.value },
                  }))
                }
                variant="outlined"
                margin="dense"
              />
            </Grid>
            {/* Category */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Category"
                value={addVideoDialog.fields.category}
                onChange={(e) =>
                  setAddVideoDialog((prev) => ({
                    ...prev,
                    fields: { ...prev.fields, category: e.target.value },
                  }))
                }
                variant="outlined"
                margin="dense"
              />
            </Grid>
            {/* Chatbot */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Chatbot"
                value={addVideoDialog.fields.chatbot}
                onChange={(e) =>
                  setAddVideoDialog((prev) => ({
                    ...prev,
                    fields: { ...prev.fields, chatbot: e.target.value },
                  }))
                }
                variant="outlined"
                margin="dense"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              setAddVideoDialog({
                open: false,
                key: '',
                fields: {
                  title: '',
                  description: '',
                  linktext: '',
                  link: '',
                  frontend: false,
                  public: false,
                  products: '',
                  category: '',
                  chatbot: '',
                },
              })
            }
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleAddVideo} color="primary">
            Add Video
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Video Dialog */}
      <Dialog
        open={deleteVideoDialog.open}
        onClose={() => setDeleteVideoDialog({ open: false, key: '' })}
      >
        <DialogTitle>Confirm Delete Video</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the video "{deleteVideoDialog.key}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteVideoDialog({ open: false, key: '' })} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteVideo} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Upload Thumbnail Dialog */}
      <Dialog
        open={uploadThumbnailDialog.open}
        onClose={() => setUploadThumbnailDialog({ open: false, key: '', file: null })}
      >
        <DialogTitle>Upload Thumbnail</DialogTitle>
        <DialogContent>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => {
              if (e.target.files.length > 0) {
                setUploadThumbnailDialog((prev) => ({ ...prev, file: e.target.files[0] }));
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUploadThumbnailDialog({ open: false, key: '', file: null })} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUploadThumbnailSubmit} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      {/* Subtitle Dialog */}
      <Dialog
        open={subtitleDialog.open}
        onClose={() =>
          setSubtitleDialog({
            open: false,
            key: '',
            index: null,
            subtitle: { label: '', srclang: '', src: '', default: false },
          })
        }
      >
        <DialogTitle>{subtitleDialog.index === null ? 'Add Subtitle' : 'Edit Subtitle'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Label */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Label"
                value={subtitleDialog.subtitle.label}
                onChange={(e) =>
                  setSubtitleDialog((prev) => ({
                    ...prev,
                    subtitle: { ...prev.subtitle, label: e.target.value },
                  }))
                }
                variant="outlined"
                margin="dense"
              />
            </Grid>
            {/* Language */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Language Code (e.g., en)"
                value={subtitleDialog.subtitle.srclang}
                onChange={(e) =>
                  setSubtitleDialog((prev) => ({
                    ...prev,
                    subtitle: { ...prev.subtitle, srclang: e.target.value },
                  }))
                }
                variant="outlined"
                margin="dense"
              />
            </Grid>
            {/* Source URL */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Subtitle URL"
                value={subtitleDialog.subtitle.src}
                onChange={(e) =>
                  setSubtitleDialog((prev) => ({
                    ...prev,
                    subtitle: { ...prev.subtitle, src: e.target.value },
                  }))
                }
                variant="outlined"
                margin="dense"
              />
            </Grid>
            {/* Default Checkbox */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={subtitleDialog.subtitle.default}
                    onChange={(e) =>
                      setSubtitleDialog((prev) => ({
                        ...prev,
                        subtitle: { ...prev.subtitle, default: e.target.checked },
                      }))
                    }
                    color="primary"
                  />
                }
                label="Default Subtitle"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              setSubtitleDialog({
                open: false,
                key: '',
                index: null,
                subtitle: { label: '', srclang: '', src: '', default: false },
              })
            }
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleSubtitleSubmit} color="primary">
            {subtitleDialog.index === null ? 'Add Subtitle' : 'Update Subtitle'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Asset Dialog */}
      <Dialog
        open={assetDialog.open}
        onClose={() => setAssetDialog({ open: false, key: '', asset: { hls: '', mp4: '' } })}
      >
        <DialogTitle>Edit Assets</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* HLS URL */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="HLS URL"
                value={assetDialog.asset.hls}
                variant="outlined"
                margin="dense"
                onChange={(e) =>
                  setAssetDialog((prev) => ({
                    ...prev,
                    asset: { ...prev.asset, hls: e.target.value },
                  }))
                }
              />
            </Grid>
            {/* MP4 URL */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="MP4 URL"
                value={assetDialog.asset.mp4}
                variant="outlined"
                margin="dense"
                onChange={(e) =>
                  setAssetDialog((prev) => ({
                    ...prev,
                    asset: { ...prev.asset, mp4: e.target.value },
                  }))
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAssetDialog({ open: false, key: '', asset: { hls: '', mp4: '' } })} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAssetSubmit} color="primary">
            Save Assets
          </Button>
        </DialogActions>
      </Dialog>

      {/* Auto-Generate Subtitles Dialog */}
      <Dialog
        open={autoSubtitleDialog.open}
        onClose={() => setAutoSubtitleDialog({ open: false, key: '', vttContent: '', loading: false })}
      >
        <DialogTitle>Auto-Generate Subtitles</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Paste the VTT content (in English) below. The process may take a few minutes.
          </DialogContentText>
          <TextField
            fullWidth
            multiline
            rows={10}
            variant="outlined"
            margin="dense"
            value={autoSubtitleDialog.vttContent}
            onChange={(e) => setAutoSubtitleDialog((prev) => ({ ...prev, vttContent: e.target.value }))}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setAutoSubtitleDialog({ open: false, key: '', vttContent: '', loading: false })}
            color="primary"
            disabled={autoSubtitleDialog.loading}
          >
            Cancel
          </Button>
          <Button onClick={handleAutoSubtitleSubmit} color="primary" disabled={autoSubtitleDialog.loading}>
            {autoSubtitleDialog.loading ? 'Processing...' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar Notification */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseNotification} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default ProtectedPage;
