import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  LinearProgress,
  Typography,
} from '@mui/material';
import { VideoUploader as ApiVideoUploader } from '@api.video/video-uploader';

function VideoUploader({ identifier, onUploadSuccess }) {
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('');
  const [uploadToken, setUploadToken] = useState('to3YG1un8sNZOKAtWYQ3R7lp');

  
 

  const handleFileSelect = (event) => {
    setUploadToken('to3YG1un8sNZOKAtWYQ3R7lp');
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setUploadStatus('');
    setUploadProgress(0);
  };

  const uploadVideo = async () => {

   

    if (!file) {
      setUploadStatus('Please select a file first');
      return;
    }

    if (!uploadToken) {
      setUploadStatus('Error: Upload token not available');
      return;
    }

    try {
      const uploader = new ApiVideoUploader({
        file,
        uploadToken,
        chunkSize: 5 * 1024 * 1024, // 5 MiB chunks
        retries: 5,
      });

      uploader.onProgress((event) => {
        const percentCompleted = Math.round((event.uploadedBytes * 100) / event.totalBytes);
        setUploadProgress(percentCompleted);
      });

      uploader.onPlayable((video) => {
        setUploadStatus('Video is playable!');
        // Extract the assets and pass them to the parent component
        if (onUploadSuccess && video.assets) {
          onUploadSuccess(video.assets);
        }
      });

      setUploadStatus('Uploading video...');
      const video = await uploader.upload();
      setUploadStatus('Upload successful! Processing video...');

      // Optionally, you can wait for the video to be fully processed
      // before calling onUploadSuccess
      // For this example, onPlayable is used to notify when the video is playable

    } catch (error) {
      setUploadStatus(`Upload failed: ${error.message}`);
      console.error('Error uploading video:', error);
    }
  };

  return (
    <Box mt={2}>
      <input
        type="file"
        accept="video/*"
        onChange={handleFileSelect}
        style={{ marginBottom: '16px' }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={uploadVideo}
        disabled={!file || !uploadToken}
      >
        Upload Video
      </Button>

      {uploadProgress > 0 && (
        <Box mt={2}>
          <LinearProgress variant="determinate" value={uploadProgress} />
          <Typography variant="body2" color="textSecondary">{`Upload Progress: ${uploadProgress}%`}</Typography>
        </Box>
      )}

      {uploadStatus && (
        <Typography
          variant="body1"
          color={uploadStatus.includes('failed') ? 'error' : 'textPrimary'}
          mt={2}
        >
          {uploadStatus}
        </Typography>
      )}
    </Box>
  );
}

export default VideoUploader;
