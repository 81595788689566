import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import VPlayer from './components/VPlayer';
import Admin from './components/Admin';
import NotFound from './components/NotFound';
import Home from './components/Home'


const App = () => {
  return (
    <Router>
      <Routes>

              {/* Empty route to handle the root path */}
              <Route path="/" element={<Home />} />

              <Route path="/admin" element={<Admin />} />

              

               {/* Empty route to handle the root path */}
               <Route path="/watch" element={<Home />} />

       
      

        {/* Main dynamic route for the video player */}
        <Route path="/watch/:videoName" element={<VPlayer />} />


        {/* Fallback route to catch all other undefined paths */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;