import React from 'react';

const Home = () => {
  return (
    <div style={{ textAlign: 'center', padding: '2rem' }}>
      <h1>IT'S COMING SOON</h1>
      <h3>THE FUTURE OF MUSIC PRODUCTION EDUCATION</h3>
    
    </div>
  );
};

export default Home;
