import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      primary: {
        main: '#CFCFCF',
      },
      background: {
        default: '#191919',
        paper: '#202020',
      },
      text: {
        primary: '#CFCFCF',
        secondary: '#ffffff',
      },
    },
    typography: {
      fontFamily: 'Oswald, Arial, sans-serif',
      h6: {
        fontSize: '28px',
        fontWeight: 600,
      },
      body2: {
        fontSize: '16px',
        fontWeight: 400,
      },
    },
    components: {
      MuiSlider: {
        styleOverrides: {
          thumb: {
            width: '16px',
            height: '16px',
            backgroundColor: '#CFCFCF',
          },
          rail: {
            backgroundColor: '#404040',
          },
          track: {
            backgroundColor: '#CFCFCF',
          },
        },
      },
    },
  });

  export default theme;